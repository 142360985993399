<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <all-orders></all-orders>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AllOrders from '../../components/orders/AllOrders.vue';

export default {
  name: 'InnerOrders',
  props: {
    //
  },
  components: {
    AllOrders,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
