<template>
  <v-card>
    <v-card-title>
      <v-icon color="primary" class="mr-4">mdi-table</v-icon>
      <span class="subtitle-1">{{ $t('innerSideNav.allOrders') }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-6">
      <v-row justify="end" class="ma-0">
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            :aria-label="$t('search')"
            :label="$t('search')"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            :hint="$t('inputMessages.searchHint')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loadingData"
        :loading-text="$t('loadingText')"
      >
        <template v-slot:item.iron="{item}">
          <v-btn text color="primary" @click="seeOrderDetails">
            {{ $t('button.view') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AllOrders',
  props: {
    //
  },
  mounted() {
    setTimeout(this.loadTableData, 1500);
  },
  data() {
    return {
      search: null,
      headers: [
        { text: this.$t('order.orderId'), value: 'name' },
        { text: this.$t('order.orderDate'), value: 'calories' },
        { text: this.$t('shipping.pickupAddress'), value: 'fat' },
        { text: this.$t('shipping.shippingAddress'), value: 'carbs' },
        { text: this.$t('order.orderStatus'), value: 'protein' },
        { text: this.$t('actions'), value: 'iron' },
      ],
      desserts: [],
      loadingData: true,
    };
  },
  methods: {
    loadTableData() {
      this.desserts = [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '5%',
        },
      ];
      this.loadingData = false;
    },
    seeOrderDetails() {
      this.$router.push({ name: 'megrendeles-reszletei' });
    },
  },
};
</script>

<style scoped>

</style>
