import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-table")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('innerSideNav.allOrders')))])],1),_c(VDivider),_c(VCardText,{staticClass:"pa-6"},[_c(VRow,{staticClass:"ma-0",attrs:{"justify":"end"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"aria-label":_vm.$t('search'),"label":_vm.$t('search'),"filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":_vm.$t('inputMessages.searchHint')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"loading":_vm.loadingData,"loading-text":_vm.$t('loadingText')},scopedSlots:_vm._u([{key:"item.iron",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.seeOrderDetails}},[_vm._v(" "+_vm._s(_vm.$t('button.view'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }